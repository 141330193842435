import React from 'react';
import './styles.scss'; // Import styles if needed
import { Trans } from '@lingui/react';

const GenerateButton = ({ isEnabled, onClick, className }) => {
  return (
    <button
      className={`generate-button ${isEnabled ? '' : 'disabled'} ${className}`}
      onClick={onClick}
      disabled={!isEnabled}
    ><Trans id="btn_request"/>
    </button>
  );
};

export default GenerateButton;
