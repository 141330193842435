export default {
  dnd_header: "Bilder für Anfrage auswählen",
  dnd_title: "Bilder hierher ziehen und ablegen",
  dnd_or: "ODER",
  dnd_select: "Bilder auswählen",
  dnd_support: "Unterstützte Formate",
  btn_request: "3D-Modell anfordern",
  btn_cancel: "Zurück",
  btn_request_final: "3D-Modell anfordern",
  form_enterName: "Geben Sie Ihren Namen ein",
  form_enterEmail: "Geben Sie Ihre E-Mail Adresse ein",
  form_message: "Anmerkungen",
  form_generate_header: "3D Modell generieren",
  form_error_name: "Name benötigt",
  form_error_email: "E-Mail benötigt",
  form_error_eminvalid: "E-Mail fehlerhaft",
  form_error_option: "Bitte Option auswählen",
  form_error_terms: "Sie müssen zustimmen um Ihr Modell zu erhalten",
  radio_try: "Einfach ausprobieren",
  radio_business: "Geschäftliches Interesse",
  radio_terms: "Für die Nutzung dieses Dienstes erkläre ich mich mit der Speicherung meiner Daten und einer eventuellen Kontaktaufnahme einverstanden.",
  final_thank:"Vielen Dank!",
  final_hint_response: "Wir melden uns in den nächsten 24 Stunden.",
  final_close:"Fertig",
  final_cite_modus: "3D Modelle sind eine Schlüsselzutat für mehr Verkäufe und guten Content",
  email_subject: "Neue 3D Anfrage über Mazing Formular",
  email_thanks: "Vielen Dank!"
};