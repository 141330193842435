import logo from './logo.svg';
import './App.scss';
import UploaderExperience from './Components/UploaderExperience';

import { I18nProvider } from '@lingui/react';
import { setupI18n } from '@lingui/core';

// Import your translation files
import enMessages from './locales/en';
import deMessages from './locales/de';
import {setBaseI18N} from "./Components/Localizer";

const browserLanguage = navigator.language || navigator.userLanguage; // Fallback for older browsers

const i18n = setupI18n({
    locale: browserLanguage && browserLanguage.startsWith('de')?'de':'en',
    messages: {
        en: enMessages,
        de: deMessages,
    },
});

setBaseI18N(i18n);

function App() {
  return (
      <I18nProvider i18n={i18n}>
          <div>
         <UploaderExperience/>
        </div>
      </I18nProvider>

  );
}

export default App;
