export default {
  dnd_header: "Upload your images and get a 3D model",
  dnd_title: "Drag and Drop your images here",
  dnd_or: "OR",
  dnd_select: "Select Images",
  dnd_support: "Supported Formats",
  btn_request: "Request 3D Model",
  btn_cancel: "Cancel",
  btn_request_final: "Request 3D Model",
  form_enterName: "Enter your name",
  form_enterEmail: "Enter your email address",
  form_message: "Leave a message",
  form_generate_header: "Generate 3D Model",
  form_error_name: "Name is required",
  form_error_email: "E-Mail is required",
  form_error_eminvalid: "E-Mail is invalid",
  form_error_option: "Please select an option",
  form_error_terms: "You must agree to the terms",
  radio_try: "Just trying it",
  radio_business: "I have a business use case",
  radio_terms: "For the use of this service I agree to the storage of my data and possible contact.",
  final_thank:"Thank you!",
  final_hint_response: "We will be in touch within 24h.",
  final_close:"Done",
  final_cite_modus: "3D models are the key to content creation",
  email_subject: "New 3D Request by Mazing Form",
  email_thanks: "Thank you!"
};