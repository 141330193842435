import React, { useState } from 'react';
import './styles.scss';
import {Trans} from "@lingui/react";
import {getTranslation} from "../Localizer"; // Import your styles

const GenerateForm = ({ onCancel, onRequestModel }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    comment: '',
    useCase: '',
    agreeToTerms: false
  });
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    comment: '',
    useCase: '',
    agreeToTerms: ''
  });

  const urlParams = new URL(window.location.href);

  const prefillMail = urlParams.searchParams.get('email');
  if(prefillMail){
    formData.email = prefillMail;
  }
  const prefillName = urlParams.searchParams.get('name');
  if(prefillName){
    formData.name = prefillName;
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const validateForm = () => {
    const newErrors = {
      name: '',
      email: '',
      comment: '',
      useCase: '',
      agreeToTerms: ''
    };
    let isValid = true;

    if (!formData.name.trim()) {
      newErrors.name = getTranslation('form_error_name');
      isValid = false;
    }

    if (!formData.email.trim()) {
      newErrors.email = getTranslation('form_error_email');
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = getTranslation('form_error_eminvalid');
      isValid = false;
    }

    if (!formData.useCase) {
      newErrors.useCase = getTranslation('form_error_option');
      isValid = false;
    }

    if (!formData.agreeToTerms) {
      newErrors.agreeToTerms = getTranslation('form_error_terms');
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      onRequestModel(formData);
    }
  };

  return (
    <div className="generate-form">
      <h1><Trans id='form_generate_header'/> </h1>
      <form onSubmit={handleSubmit}>

        <input
            type="text"
            name="name"
            placeholder={getTranslation('form_enterName')}
            value={formData.name}
            onChange={handleChange}
        />
        {errors.name && <div className="error-text">{errors.name}</div>}


        <input
            type="email"
            name="email"
            placeholder={getTranslation('form_enterEmail')}
            value={formData.email}
            onChange={handleChange}
        />
        {errors.email && <div className="error-text">{errors.email}</div>}


        <textarea 
          name="comment" 
          placeholder={getTranslation('form_message')}
          value={formData.comment} 
          onChange={handleChange} 
        />
        {errors.comment && <div className="error-text">{errors.comment}</div>}

        <div className="radio-group">
          <label className="cursor">
            <input
              type="radio" 
              name="useCase" 
              value="Just trying it" 
              checked={formData.useCase === 'Just trying it'}
              onChange={handleChange}
            />
            <Trans id="radio_try"/>

          </label>
          <label className="cursor">
            <input 
              type="radio" 
              name="useCase" 
              value="I have a business use case"
              checked={formData.useCase === 'I have a business use case'}
              onChange={handleChange}
            />
            <Trans id="radio_business"/>
          </label>
        </div>
        {errors.useCase && <div className="error-text">{errors.useCase}</div>}

        <label className="checkbox-group cursor">
          <input 
            type="checkbox" 
            name="agreeToTerms" 
            checked={formData.agreeToTerms}
            onChange={handleChange}
          />
          <Trans id="radio_terms"/>
        </label>
        {errors.agreeToTerms && <div className="error-text">{errors.agreeToTerms}</div>}

        <div className="button-group">
          <button type="button" className="cancel-button" onClick={onCancel}><Trans id="btn_cancel"/></button>
          <button type="submit" className="request-button"><Trans id="btn_request_final"/></button>
        </div>
      </form>
    </div>
  );
};

export default GenerateForm;
