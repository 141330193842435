import React, {useState} from 'react';
import {Container} from 'react-bootstrap';
import DragAndDropArea from "../DragAndDropArea";
import GenerateButton from "../GenerateButton";
import GenerateForm from "../GenerateForm";
import Loading from "../Loading";
import FinalMessage from "../FinalMessage";
import {storage, firestore} from '../../firebaseConfig'; // Import Firebase services
import {ref, uploadBytes, getDownloadURL} from 'firebase/storage';
import {collection, addDoc} from "firebase/firestore";
import {getTranslation} from "../Localizer";

function UploaderExperience() {
    const [images, setImages] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showFinalMessage, setShowFinalMessage] = useState(false);

    const handleImagesChange = (newImages) => {
        setImages(newImages);
    };

    const handleGenerateButtonClick = () => {
        setShowForm(true);
    };

    const handleCancel = () => {
        setShowForm(false);
        setLoading(false); // Ensure loading is not active
        setShowFinalMessage(false); // Ensure final message is not visible
    };

    const printArrayWithNumbers = (arr) => {
        return arr.map((element, index) => `${index + 1}. ${element}\n`).join('<br><br>');
    }
    const handleRequestModelClick = async (formData) => {
        setShowForm(false);
        setLoading(true);
        try {
            const imageLinks = await uploadImages(images);
            const secretKey = process.env.REACT_APP_SECRET_KEY;  // Access the environment variable

            const urlParams = new URLSearchParams(window.location.search);
            const sourceParam = urlParams.get('source');

            await addDoc(collection(firestore, "submissions"), {
                ...formData,
                images: imageLinks,
                secretKey,  // Include the secret key in the document
                createdAt: new Date(),
                to: ['office@mazingxr.com', formData.email],
                message: {
                    subject: getTranslation('email_subject'),
                    text: '',
                    html: 'Email: ' + formData.email
                        + '<br> Name: ' + formData.name
                        + '<br> Text: ' + formData.comment
                        + '<br> Info: ' + formData.useCase
                        + '<br> Region: ' + navigator.language
                        + '<br> Form: ' + (sourceParam ? sourceParam:'unknown')
                        +'<br><br><br>Images:<br><br>' + printArrayWithNumbers(imageLinks)+
                        + '<br><br><br>' + getTranslation('email_thanks')
                }
            });

            setLoading(false);
            setShowFinalMessage(true);
        } catch (error) {
            console.error("Error uploading data or images:", error);
            setLoading(false);
        }
    };

    const uploadImages = async (images) => {
        const secretKey = process.env.REACT_APP_SECRET_KEY; // Access the secret key
        const uploadPromises = images.map(image => {
            const storageRef = ref(storage, `images/${image.file.name}`);
            return uploadBytes(storageRef, image.file)
                .then(snapshot => getDownloadURL(snapshot.ref));
        });
        return Promise.all(uploadPromises);
    };

    const handleCloseFinalMessage = () => {
        setShowFinalMessage(false);
        setImages([]); // Optionally clear images or handle as needed
        setShowForm(false); // Optionally go back to the initial state
    };

    return (
        <Container fluid className="d-flex flex-column justify-content-center align-items-center min-vh-100">
            {!showForm && !loading && !showFinalMessage ? (
                <div className="d-flex flex-column align-items-center w-100" style={{maxWidth: '400px'}}>
                    <DragAndDropArea onImagesChange={handleImagesChange} images={images}/>
                    <GenerateButton
                        isEnabled={images.length > 0}
                        onClick={handleGenerateButtonClick}
                        className="w-100"
                    />
                </div>
            ) : showForm ? (
                <GenerateForm onCancel={handleCancel} onRequestModel={handleRequestModelClick}/>
            ) : loading ? (
                <Loading/>
            ) : (
                <FinalMessage onClose={handleCloseFinalMessage}/>
            )}
        </Container>
    );
}

export default UploaderExperience;
