import React, { useRef } from 'react';
import './styles.scss';
import { Trans } from '@lingui/react';

const DragAndDropArea = ({ onImagesChange, images }) => {
  const fileInputRef = useRef(null);

  const handleDrop = (e) => {
    e.preventDefault();
    addImages(e.dataTransfer.files);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    addImages(e.target.files);
  };

  const addImages = (files) => {
    if (images.length >= 3) return;

    const newImages = Array.from(files)
      .filter(file => file.type.startsWith('image/'))
      .slice(0, 3 - images.length)
      .map(file => ({
        file,
        preview: URL.createObjectURL(file)
      }));

    const updatedImages = [...images, ...newImages];
    onImagesChange(updatedImages);
  };

  const removeImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    onImagesChange(updatedImages);
  };

  return (
      <div className="drag-and-drop-container">
        <div
            className="drop-zone"
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
        >
          <p className="sub-text"><Trans id="dnd_header"/></p>
          <div className="drop-text"><Trans id="dnd_title"/></div>
          <div className="or-text"><Trans id="dnd_or"/></div>
          <button className="upload-button" onClick={handleClick}>
            <span className="upload-icon">+</span><Trans id="dnd_select"/>
          </button>
          <p className="supported-files-text"><b><Trans id="dnd_support"/></b><br/><i>.jpg .jpeg .png .webp</i></p>
          <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept="image/*"
              multiple
              style={{display: 'none'}}
          />
          <div className="image-preview">
            {images.map((image, index) => (
                <div key={index} className="preview-image-container">
                  <img
                      src={image.preview}
                      alt={`Uploaded ${index + 1}`}
                      className="preview-image"
                  />
                  <button
                      className="remove-image"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeImage(index);
                      }}
                  >
                    ×
                  </button>
                </div>
            ))}
            {images.length < 3 && (
                <div className="add-image-container" onClick={handleClick}>
                  <div className="add-image-icon">+</div>
                </div>
            )}
          </div>
        </div>
      </div>
  );
};

export default DragAndDropArea;
