import React from 'react';
import './styles.scss';
import {Trans} from "@lingui/react"; // Import your styles

const FinalMessage = ({ onClose }) => {
  return (
    <div className="final-message-container">
      <div className="final-message-heading"><Trans id="final_thank"/></div>
      <div className="final-message-body">
        <p className="final-message-text"><Trans id="final_hint_response"/></p>
        <p className="final-message-subtext">"<Trans id="final_cite_modus"/>" <br/>CMO Modus</p>
      </div>
      <button className="final-message-close" onClick={onClose}><Trans id="final_close"/></button>
    </div>
  );
};

export default FinalMessage;
