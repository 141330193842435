let i18n;
export function setBaseI18N(newI18N) {
    i18n = newI18N;
}
// function translateWithVariables(resourceKey, variables = {}) {
//     // Get the translation from the resource file
//     const translation = i18n._([resourceKey]);
//     console.error('translation', translation);
//     console.error('resourceKey', resourceKey);
//     // Replace placeholders in the translation with actual values
//     return Object.keys(variables).reduce((acc, key) => {
//         return acc.replace(new RegExp(`\\{${key}\\}`, 'g'), variables[key]);
//     }, translation);
// }

// Helper function to get the translation
export function getTranslation(key) {
    return i18n.messages[key];
}

// Helper function to get the translation with variables
export function getTranslatedString(key, variables) {
    // return translateWithVariables(key, variables);
}